import React from 'react'

import './Style.css';

import Form from './Form';




const Home = ({val,setVal}) => {
  return (
    <div className="App">
          

    

    <main>

   

        <Form setVal={setVal} val={val}/>

    </main>

    



    </div>
  )
}

export default Home