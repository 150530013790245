import React, { useContext } from 'react'
import {MyContext} from '../App.js'

const Portfolio = () => {

  const {val1,nameProp,emailProp,eduProp,expProp,hobProp} = useContext(MyContext);
  
  const [val, setVal] = val1

  const [name, setName] = nameProp;
  const [email, setEmail] = emailProp;

  // Lists of Education (edu), Experince (exp) and Hobbies (hob)
  const [edu, setEdu] = eduProp;
  const [exp, setExp] = expProp;
  const [hob, setHob] = hobProp;

  const updateState = () => {setVal(5)}
  return (
    <div>Portfolio
{val}

      
      <button onClick={() => updateState()}>Make it 5</button>
    </div>
  )
}

export default Portfolio