import React from 'react'

const Result = ({hash}) => {

    const url = "https://portfolio.tricksumo.com/"+hash+".html";

    
  return (
    <div style={{border:"solid black 2px", margin:"10px",textAlign:"center"}}>
        
        <h3 style={{padding:"5px"}}>Shareable URL for your portfolio is:- </h3> <a href={url} target="_blank">{url}</a>
    </div>
  )
}

export default Result